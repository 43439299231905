<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoBellEnjoy",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoBellEnjoy",
				row2: {},
				data: {
					data: []
				}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			}
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + Page.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			submitHero(e) {
				if (e && e.btnLoading()) return;
				BOGen.apirest('/' + Page.Name, {
					data: this.row2,
					type: 'updateHero'
				}, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.hero-info')
						setTimeout(() => {
							$("#collapseExample").hide()
						}, 3000);
						this.refreshData()
					}
				}, "POST");
			},
			showEdit() {
				if ($("#collapseExample").css("display") == "none") $("#collapseExample").show()
				else $("#collapseExample").hide()
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row">
			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title mb-0">{{ObjectName}}</h5>
							</div>
							<hr>
							<h5 class="card-title">End Hero Image</h5>
							<div class="row">
								<div class="col-md-8">
									<div class="wrap_slider_img">
										<img :src="uploader(row2.shi_image_desktop)" class="img-responsive" />
										<div class="slider_name">
											<p>End Hero Image</p>
										</div>
										<!-- <a data-toggle="collapse" v-if="page.moduleRole('Update')" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a> -->
										<a href="javascript:;" v-if="page.moduleRole('Update')" @click="showEdit" class="bullet_edit"><i
												class="fas fa-pencil-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row collapse mt-4" id="collapseExample">
								<div class="row">
									<div class="hero-info col-12"></div>
								</div>
								<div class="row">
									<div class="col-md-4 mb-3">
										<BoField :label="'Background Image'" name="shi_image_desktop" mandatory>
											<Uploader :param="{thumbnail:true}" name="shi_image_desktop" v-model="row2.shi_image_desktop"
												type="hero_descktop" uploadType="cropping"></Uploader>
										</BoField>
										<BoField :label="'Logo Image'" name="shi_end_hero">
											<Uploader :param="{thumbnail:true}" name="shi_end_hero" v-model="row2.shi_end_hero"
												type="title_logo" uploadType="cropping">
											</Uploader>
										</BoField>
									</div>
									<div class="col-md-8 mb-3">
										<div class="row">
											<div class="col-md-12">
												<div class="panel_group">
													<div class="panel_heading">Heading</div>
													<div class="panel_body">
														<div class="row">
															<div class="col-md-9">
																<BoField name="shi_title_header_en" v-model="row2.shi_title_header_en"></BoField>
															</div>
															<div class="col-md-9">
																<BoField name="shi_title_header_id" v-model="row2.shi_title_header_id"></BoField>
															</div>
															<div class="col-md-2">
																<BoField :attr="{type:'color'}" name="shi_title_header_color"
																	v-model="row2.shi_title_header_color"></BoField>
															</div>
														</div>
													</div>
												</div>
											</div>
											<!-- <div class="col-md-12">
												<div class="row">
													<div class="col-md-12">
														<div class="panel_group">
															<div class="panel_heading">Sub-Heading</div>
															<div class="panel_body">
																<div class="row">
																	<div class="col-md-9">
																		<div class="form-group">
																			<BoField name="shi_title_subheader_en" v-model="row2.shi_title_subheader_en">
																			</BoField>
																		</div>
																	</div>

																	<div class="col-md-9">
																		<BoField name="shi_title_subheader_id" v-model="row2.shi_title_subheader_id">
																		</BoField>
																	</div>
																	<div class="col-md-2">
																		<div class="form-group">
																			<label class="control-label">Color </label>
																			<input class="form-control" type="color" id="favcolor" name="favcolor"
																				v-model="row2.shi_title_subheader_color">
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> -->
											<div class="col-4 mt-3">
												<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>
			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title mb-0">How to Enjoy
								<span class="card-subtitle">Drag image to move the position.</span>
							</h5>
						</div>
						<hr>
						<draggable class="row" v-model="data.data" tag="tbody" @end="endDrag">
							<div class="col-md-6 item-list" v-for="(v,k) in data.data" :key="k">
								<div class="form-row">
									<div class="col-md-6">
										<div class="product-img">
											<img :src="uploader(v.ae_image)" alt="prod">
											<div class="pro-img-overlay">
												<router-link class="bg-info" v-if="page.moduleRole('Update')" :to="{name:Name,params:{id:v.id}}"
													v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
												<a href="javascript:;" v-if="page.moduleRole('Remove')" class="bg-danger"
													@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
											</div>
											<span class="label label-success" v-if="v.ae_is_active=='Y'">Active</span>
											<span class="label label-danger" v-else>Inactive</span>
										</div>
									</div>
									<div class="col-md-6">
										<h5 class="card-title">{{v.ae_title_en}}</h5>
										<div class="item-desc" v-html="(v.ae_desc_en||'').limitChar(200)"></div>
									</div>
								</div>
							</div>
						</draggable>
						<div class="row" v-if="NotFound">
							<div class="col-12 text-center">
								<h3 class="tc">{{NotFound}}</h3>
							</div>
						</div>
						<div class="row" v-if="data.data===false">
							<div class="col-12 text-center">
								<LoadingSpinner light></LoadingSpinner>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="$route.params.id">
				<VForm @resp="submitForm" method="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-12 info"></div>
								<div class="col-md-4">
									<BoField name="ae_image" mandatory>
										<Uploader :param="{thumbnail:true}" name="ae_image" v-model="row.ae_image" type="enjoy"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8">
									<div class="row">
										<div class="col-8">
											<BoField name="ae_title_en" v-model="row.ae_title_en" :attr="{placeholder:'e.g Transport'}">
											</BoField>
										</div>
										<div class="col-8">
											<BoField name="ae_title_id" v-model="row.ae_title_id" :attr="{placeholder:'e.g Transport'}">
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ae_desc_en">
												<CKEditor type='noImage' name="ae_desc_en" v-model="row.ae_desc_en"
													v-bind="validation('ae_desc_en')">
												</CKEditor>
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ae_desc_id">
												<CKEditor type='noImage' name="ae_desc_id" v-model="row.ae_desc_id"
													v-bind="validation('ae_desc_id')">
												</CKEditor>
											</BoField>
										</div>
										<div class="col-8">
											<BoField name="ae_is_active">
												<div class="row">
													<div class="col-2">
														<radio name="ae_is_active" v-model="row.ae_is_active" option="Y"
															:attr="validation('ae_is_active')">Active</radio>
													</div>
													<div class="col-2">
														<radio name="ae_is_active" v-model="row.ae_is_active" option="N">Inactive</radio>
													</div>
												</div>
											</BoField>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="d-flex justify-content-end">
								<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit</button>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>